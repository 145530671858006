<template>
  <div v-if="isMobile" class="container has-space">
      <MobileHeaderBack></MobileHeaderBack>
      <div class="signup-form__title">{{$t(title)}}</div>
      <DeleteAccount></DeleteAccount>
  </div>
  <AccountIndex v-else>
    <div class="account-content__wrapper">
      <DeleteAccount></DeleteAccount>
    </div>
  </AccountIndex>
</template>

<script>
import MobileHeaderBack from "@/components/mobile/HeaderBack.vue";
import AccountIndex from "@/views/account/Index.vue";
import DeleteAccount from "@/components/user/DeleteAccount.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    AccountIndex,
    MobileHeaderBack,
    DeleteAccount,
  },
  data() {
    return {
      title: "",
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters([
      "isMobile",
      "currentLocale",
      "isLogin",
      "userInfo",
      "currentLanguageObj",
    ]),
  },
  methods: {
    init() {
      this.title = this.$route.meta.name;
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .signup-form__title {
    margin-bottom: 1.5rem;
  }
}
</style>
